export default {
	methods: {
		subRankIndicator(item, subRankType) {
			try {
				const subRankSplitted = item[subRankType].split('_');
				return subRankSplitted[subRankSplitted.length - 1] || null;
			} catch (error) {
				return null;
			}
		},
	},
};
